// Colors
$c-pink: #FF5082;
$c-pink-tint: rgba(255, 80, 130, 0.2);
$c-green: #1ADEA3;
$c-green-tint: rgba(26, 222, 163, 0.2);
$c-blue: #00CDFF;
$c-blue-tint: rgba(0, 205, 255, 0.2);
$c-red: #F1584F;
$c-red-tint: rgba(241, 88, 79, 0.2);
$c-yellow: #E4F14F;
$c-yellow-tint: rgba(228, 241, 79, 0.2);
$c-orange: rgba(243, 141, 46, 1);


$c-background-1: #0A1D26;
$c-background-1-transparent: rgba(10, 29, 38, 0.85);
$c-background-2: #1B2D35;
$c-background-2-transparent: rgba(27, 45, 53, 0.86);
$c-background-3: #2C3D44;
$c-background-3-transparent: rgba(44, 61, 68, 0.85);
$c-background-3: #3D4C54;
$c-background-3-transparent: rgba(61, 76, 84, 0.85);

$c-scrim: rgba(10, 29, 38, 0.6);
$c-separator: rgba(255, 255, 255, 0.14);
$c-label: rgba(245, 245, 250, 0.72);
$c-label-2: rgba(235, 235, 245, 0.6);
$c-label-3: rgba(235, 235, 245, 0.3);

$c-menu-item: #BABABA;


$h-mobile-header: 3.5rem;
$h-mobile-footer: 4.5rem;
$h-browser-header: 3.5rem;
$h-browser-footer: 4rem;
$h-browser-subnav: 3rem;

$w-browser-container: 70rem;
