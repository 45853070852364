@import url("https://fonts.googleapis.com/css?family=Poppins");

.landing-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.landing-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.landing-background {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("/images/landing/welcome-background.jpg");
  // background-position: -200px -600px;
  background-size: cover;
}

.landing-gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #4b6c7b 0%, #2f6781 100%);
  mix-blend-mode: multiply;
  opacity: 0.95;
}

.landing-content {
  width: 100%;
  position: relative;
  padding-top: 6rem;
}

.landing-header {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

.landing-login-button {
  color: white;
  background: none;
  border: 2px solid white;
  border-radius: 0.5rem;
  width: 124px;
  height: 44px;
  font-weight: 600;
}

.landing-banner {
  padding-top: 5rem;
  font-family: "Poppins";
  text-align: center;

  h1 {
    @include font-size(3.25rem);
    line-height: 1.75;
    font-weight: 600;
  }

  p {
    @include font-size(1.375rem);
  }
}
