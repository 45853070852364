@import '~rfs/scss';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: .9rem;
  line-height: 133%;
}


.subheadline {
  font-weight: 400;
  @include font-size(.9rem);
  line-height: 1.25;
  color: $c-label;
  letter-spacing: -0.24px;
}

.title-large {
  @include font-size(1.75rem);
  // font-weight: bold;
  line-height: 1.2;
  letter-spacing: .374px;
}

.title-1 {
  @include font-size(1.75rem);
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.364px;
}

.title {
  @include font-size(1.25rem);
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: .5rem;
}

.caption {
  @include font-size(.75rem);
  font-weight: 500;
  line-height: 1rem;
  color: $c-label-2;
}

.small-text {
  @extend .caption;
  font-weight: 400;

}

.gradient-text {
  background: linear-gradient(90.62deg, #FF506D 0.25%, #FF50D9 99.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
