.activities-list-item {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $c-separator;
  cursor: pointer;

  &__header {
    display: flex;
    justify-content: space-between;
    background: red;
    width: 250px;
    p {
      // display: inline-block;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    svg {
      opacity: 0.6;
    }
  }
}

.browser-activities {
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.browser-activities-list {
  padding-top: $h-browser-header;
  overflow-y: scroll;
}

.activities-list-item:hover,
.activities-list-item--active {
  background: $c-background-2-transparent;
}

.browser-activities-content {
  flex: 1;
  padding-top: $h-browser-header;
  overflow-y: scroll;
}

.mobile-activity {
  padding: 1rem;
}

.browser-activity {
  padding: 2rem;
  max-width: 65rem;
  margin: 0 auto;
}
// ----------------------------------

.activity-container {
  max-width: 50rem;
  margin: 0 auto;
}

.activity-section {
  margin-bottom: 2.5rem;
}

.activity-header {
  margin-top: 2.5rem;
}

.activity-map {
  height: 20rem;
  background: grey;
}

.activity-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
}

.activity-motion-analytics-grid {
  display: grid;
  grid-gap: 1rem;
}

.activity-motion-analytics-item {
  display: flex;
  background: $c-background-2-transparent;
  padding: 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    background: $c-background-3-transparent;
    border-radius: 50%;
    &--2 {
      @extend .activity-motion-analytics-item__icon;
      color: $c-green;
    }

    &--1 {
      @extend .activity-motion-analytics-item__icon;
      color: $c-orange;
    }

    &--0 {
      @extend .activity-motion-analytics-item__icon;
      color: $c-red;
    }
  }

  &__content {
  }

  &__title {
    padding-bottom: 0.2rem;
  }

  &__metric {
    font-size: 1.25rem;
    &--2 {
      @extend .activity-motion-analytics-item__metric;
      color: $c-green;
    }

    &--1 {
      @extend .activity-motion-analytics-item__metric;
      color: $c-orange;
    }

    &--0 {
      @extend .activity-motion-analytics-item__metric;
      color: $c-red;
    }
  }
}

// ----------------------------------
// .activity-metrics {
//   display: flex;
//   margin: 2rem 0;
//   flex-wrap: wrap;
// }

.activity-segments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.3rem;
  padding-right: 1rem;
}

.activity-segments-selector {
  display: flex;
  background: $c-background-2;
  width: 300px;
  border-radius: 0.5rem;
  padding: 2px;

  &__item {
    padding: 0.5rem 0;
    text-align: center;
    width: 50%;
    cursor: pointer;

    &--active {
      @extend .activity-segments-selector__item;
      background: $c-background-3;
      border-radius: 0.5rem;
    }
  }
}

.activity-segment {
  padding: 1rem;
  background: $c-background-2;
  border-radius: 0.75rem;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    &__section {
      display: flex;
      align-items: center;
    }

    &__icon {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.5rem;
      background: $c-background-3;
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__distance {
      font-weight: 600;
    }

    &__equipment {
    }

    &__duration {
      font-weight: 600;
    }
  }
}

.activity-segment-rest {
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $c-label-2;
}

.activity-laps {
  display: table;
}

.activity-lap {
  display: table-row;

  &--even {
    background: $c-background-3;
  }

  &__cell {
    display: table-cell;
    padding: 0.5rem;

    &--left {
      @extend .activity-lap__cell;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      display: flex;
      color: $c-label-2;
    }

    &--center {
      width: 100%;
      @extend .activity-lap__cell;
      vertical-align: middle;
    }

    &--right {
      @extend .activity-lap__cell;
      text-align: right;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}

.activity-lap-meter {
  background: linear-gradient(90.62deg, #ff506d 0.25%, #ff50d9 99.71%);
  height: 1rem;
  border-radius: 0.15rem;
  display: flex;
  justify-content: flex-end;

  &--cover {
    background: $c-background-2;
    transition: width 0.5s ease-in-out;
  }

  &--cover-light {
    background: $c-background-3;
    transition: width 0.5s ease-in-out;
  }
}

.lap-stroke-label {
  text-transform: uppercase;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding-left: 1rem;
}

.activity-workout {
  margin-top: 2rem;
}

.empty-activities-wrapper {
  height: 100vh;
}

.empty-activity {
  background: $c-background-2-transparent;
  padding: 1.2rem;
  border-radius: 1rem;
  display: flex;
  max-width: 24rem;

  &__content {
    padding-left: 1.5rem;
    h2 {
      font-weight: bold;
      line-height: 1.4rem;
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
    }

    p {
      line-height: 1.4rem;
      letter-spacing: -0.24px;
      color: $c-label-2;
    }
  }
}

// Activity Feedback

.activity-feedback {
  border: 2px solid $c-background-3;
  border-radius: 1rem;
  padding: 1.5rem;

  textarea {
    margin: 1rem 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    svg {
      fill: $c-label-3;
    }
  }
}

.activity-feedback__icons {
  display: flex;
  padding-top: 1rem;
}

.activity-emoji {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
  min-width: 4rem;
  cursor: pointer;
  &__emoji-container {
    width: 44px;
    height: 44px;
    background: $c-background-3-transparent;
    border-radius: 50%;
    span {
      font-size: 26px;
      line-height: 1;
    }
  }

  &__text {
    font-size: 0.75rem;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  &--active {
    @extend .activity-emoji;

    .activity-emoji__emoji-container {
      background: grey;
    }

    .activity-emoji__text {
      color: $c-pink;
    }
  }
}
