.card-wrapper {
  max-width: 45rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  h1 {
    margin-bottom: 1rem;
  }
}

.card {
  background: $c-background-2-transparent;
  padding: 1.5rem;
  border-radius: .75rem;
}
