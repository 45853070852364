.chart-container {
  height: 280px;
}

.chart-wrapper {
  margin: 0 0;
  margin-bottom: 2rem;
}

// .chart-buttons {
//   text-align: center;
//   button {
//     margin-right: 1rem;
//   }
// }

.chart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
}

.chart-label {
  color: $c-label-2;
}

.recharts-label {
  fill: $c-label-2;
  font-size: 0.8rem;
}

.recharts-brush {
}

.recharts-brush-slide {
  stroke: transparent;
  fill: $c-background-3-transparent;
}

.recharts-brush-traveller {
  rect {
    fill: $c-label-2;
    stroke: $c-label-2;
  }
}

.recharts-cartesian-axis-tick {
  font-size: 0.8rem;
  font-family: sans-serif;
}

.custom-tooltip {
  background: $c-background-1-transparent;
  padding: 0.5rem;
  border-radius: 0.25rem;

  .tooltip-label {
    padding-bottom: 0.25rem;
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    &:last-child {
      padding-bottom: 0;
    }
  }
}
