.swimmer-list-table {
  display: table;
  width: 100%;
  border-collapse: separate;

  &__header-row {
    display: table-row;
    background: $c-background-2-transparent;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: rgba(235, 235, 245, 0.6);
  }

  &__row {
    display: table-row;
    // background: $c-background-2-transparent;
    // cursor: pointer;
    // &:hover {
    //   opacity: .8;
    // }
  }

  &__row-spacer {
    height: 1rem;
  }

  &__header-cell {
    display: table-cell;
    padding: .5rem 1rem;
    &:first-child {
      border-top-left-radius: .5rem;
      border-bottom-left-radius: .5rem;

    }

    &:last-child {
      border-top-right-radius: .5rem;
      border-bottom-right-radius: .5rem;
    }
  }

  &__cell {
    display: table-cell;
    padding: 1.6rem 1rem;
    // font-size: .8rem;
    border-bottom: 1px solid $c-separator;
    line-height: 1.4rem;

    &--name {
      @extend .swimmer-list-table__cell;
      font-size: 1.2rem;
      // letter-spacing: 0.38px;
      a {
        color: $c-blue;
      }
    }

    &--request {
      border: none;
      padding: 1rem;
    }
  }
}

.swimmer-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  padding-top: $h-browser-header;
}

.swimmer-content-wrapper {
  flex: 1;
  display: flex;
  max-height: 100%;
  overflow: hidden;
}

.coach-activities {
  display: flex;
  height: 100%;
}

.coach-activities-list {
  overflow-y: scroll;
  height: 100%;
}

.coach-activities-content {
  overflow-y: scroll;
  height: 100%;
  flex: 1;
}

.swimmer-list-table-loading-item {
  background: $c-background-2-transparent;
  height: 2.5rem;
  border-radius: .5rem;
  width: 100%;
  padding: .5rem;
}

.swimmer-list-table-row {
  // display: table-row;
}

.swimmer-list-table-cell {
  // display: table-cell;
}

.coaching-requests {
  margin-bottom: 2rem;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $c-background-3;
    padding: .7rem 1rem;
    border-radius: .5rem;
    cursor: pointer;
    svg {
      fill: $c-pink;
    }
  }

  &__list {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    &--open {
      overflow: hidden;
      max-height: 300px;
      transition: max-height 0.5s ease-in-out;
    }
  }
}
