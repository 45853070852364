.profile {
  max-width: 50rem;
  .title-large {
    margin-bottom: 1rem;
  }
}

.profile-section {
  background: $c-background-2-transparent;
  padding: .75rem;
  border-radius: .5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  cursor: pointer;

  &__sub {
    display: flex;
    align-items: center;

    &--left {
      @extend .profile-section__sub;
      // display: flex;
    }

    &--right {
      @extend .profile-section__sub;
      // display: flex;
      // justify-content: center;
      color: $c-label;
    }
  }

}

.profile-photo {
  width: 3.5rem;
  height: 3.5rem;
  display: block;
  margin-right: 1rem;
}

.profile-arrow {
  color: $c-label;
  margin-left: 1rem;
}

.profile-coaches-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1.5rem;
}

.profile-coach {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    border-radius: 50%;
    width: 4.5rem;
    height: 4.5rem;
    display: block;
  }

  &__name {
    font-weight: 600;
    padding: .9rem 0;
    font-size: 1.1rem;
  }

  &__button {
    background: linear-gradient(90.62deg, #FF506D 0.25%, #FF50D9 99.71%);
    padding: .5rem .75rem;
    color: white;
    border-radius: .5rem;
    font-size: .9rem;
  }
}
