.color-pink {
  color: $c-pink;
}

.color-green {
  color: $c-green;
}

.color-blue {
  color: $c-blue;
}

.color-red {
  color: $c-red;
}

.image-round {
  border-radius: 50%;
}

.flex-center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-center {
  @extend .flex-center-all;
  height: 100vh;
  width: 100vw;
}

.flex-inbetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.truncate-wrap {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.truncate-inner {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
