* {
	vertical-align: baseline;
	font-weight: inherit;
	font-family: inherit;
	font-style: inherit;
	font-size: 100%;
	border: 0 none;
	outline: 0;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
  //background: $c-background-1;
  //color: white;
}

a {
  color: white;
  text-decoration: none;
}

a, button {
	cursor: pointer;
	&:hover {
		opacity: .8;
	}
}

button {
	background: none;
	color: $c-pink;
	&:disabled {
		opacity: .5;
	}
}

h1, h2, h3, h4, h5 {
	margin: 0;
}
