.workouts-browser-wrapper {
 padding-top: $h-browser-header;
}

.workout-list {
  margin-top: 2rem;
}

.workout-list-item {
  background: $c-background-2;
  padding: 1rem;
  display: flex;
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    @extend .workout-list-item;
    background: $c-background-3-transparent;
  }

  &__image {
    width: 90px;
    height: 90px;
    margin-right: 1.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }
  p {
    opacity: .7;
    padding-top: .5rem;
    margin-bottom: .5rem;
  }

  a, button {
    margin-right: .5rem;
  }

  a { color: $c-green}

  button { color: $c-red }
}
