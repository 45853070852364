.mobile-layout {
  position: relative;
  header {
    height: $h-mobile-header;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  main {
    padding-top: $h-mobile-header;
    padding-bottom: $h-mobile-footer;
  }

  footer {
    background: $c-background-2;
    height: $h-mobile-footer;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.header {
  background: $c-background-2-transparent;
}

.header-coaching {
  background: $c-background-3-transparent;
}

.mobile-footer {
  display: grid;
  padding: 0.5rem;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  &__section {
    display: flex;
    justify-content: center;
  }

  &__link {
    text-align: center;
    opacity: 0.6;

    font-size: 0.6rem;
    p {
      padding: 0;
      margin: 0;
      line-height: 1;
    }

    &--active {
      @extend .mobile-footer__link;
      color: $c-pink;
      opacity: 1;
    }
  }
}

.mobile-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__section {
    padding: 0.75rem;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
    color: $c-pink;
    &--left {
      @extend .mobile-header__section;
      justify-content: flex-start;
    }
    &--center {
      @extend .mobile-header__section;
      justify-content: flex-start;
      font-weight: bold;
      color: white;
    }
    &--right {
      @extend .mobile-header__section;
      justify-content: flex-end;
    }
  }
}

.browser-layout {
  header {
    height: $h-browser-header;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  main {
    // padding-top: $h-browser-header;
  }
}

.browser-header-section {
  display: flex;
  align-content: center;
  &--left {
    @extend .browser-header-section;
    justify-content: flex-start;
    flex: 1;
    color: $c-pink;
  }
  &--center {
    @extend .browser-header-section;
    justify-content: center;
  }
  &--right {
    @extend .browser-header-section;
    justify-content: flex-end;
    flex: 1;
  }
}

.browser-header-link {
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
  p {
    @include font-size(0.9rem);
    margin-left: 0.5rem;
  }

  &--active {
    @extend .browser-header-link;
    color: $c-pink;
  }
}

.nav-link {
  &--active {
    color: $c-pink;
  }
}

.subnav {
  height: $h-browser-subnav;
  background: $c-background-2-transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;

  &__backbutton {
    color: white;
    font-size: 1.375rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    svg {
      color: $c-pink;
      margin-right: 0.5rem;
    }
  }
}

.sidenav {
  width: 15rem;
  padding: 1rem;

  &__item {
    display: block;
    padding: 0.8rem 1rem;
    font-weight: 500;
    color: rgba(235, 235, 245, 0.6);
    cursor: pointer;

    &--active {
      @extend .sidenav__item;
      background: $c-background-2-transparent;
      color: $c-pink;
    }
  }
}

.browser-subnav-link {
  font-size: 1.2rem;
  margin-right: 2rem;
  &--active {
    @extend .browser-subnav-link;
    color: $c-pink;
  }
}

.browser-container {
  width: $w-browser-container;
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem;
}

// Header profile
.header__profile-wrapper {
  position: relative;
  text-align: right;
  height: $h-browser-header;
  // margin-right: 1.2rem;
  // min-width: '310px';

  .header__profile {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;
    height: 100%;
    // min-width: 8rem;

    img {
      // border-radius: 50%;
      // height: 36px;
      // width: 36px;
    }

    .header__profile__name {
      // margin-left: 10px;
      // margin-top: 0px;
      // margin-bottom: 0px;
    }

    .header__profile__icon {
      // margin-left: 8px;
    }
  }
}

.header__profile__menu {
  z-index: 101;
  // width: '100%';
  min-width: 8rem;
  // padding: 15px 0;
  background: $c-background-3;

  .theme-icon:hover {
    cursor: pointer;
  }

  .header__profile__menu-divider {
    padding-bottom: 0.25rem;
    margin-top: 0.25rem;
    border-top: 1px solid $c-separator;
  }

  .header__profile__menu-link {
    display: flex;
    justify-content: flex-start;
    padding: 0.25rem 1.4rem;
    transition: all 0.3s;
    height: 2rem;
    position: relative;
    cursor: pointer;
    align-items: center;
    // background: blue;

    // &:before {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   height: 100%;
    //   width: 2px;
    //   // background: $ss-blue-400;
    //   opacity: 0;
    //   transition: all 0.3s;
    // }

    // &:hover {
    //
    //
    //   &:before {
    //     opacity: 1;
    //   }
    // }

    .header__link-title {
      margin: 0;
      font-size: 14px;
      line-height: 16px;
    }

    .header__link-icon {
      // margin-right: 10px;
      font-size: 13px;
      line-height: 13px;
    }
  }
}

.left-menu-layout {
  padding-top: $h-browser-header;
  display: flex;
  height: 100vh;

  &__menu {
    width: 10rem;
    background: red;
    height: 100%;
    padding-top: 2rem;
    background: $c-background-2-transparent;
    display: flex;
    flex-direction: column;
    &__item {
      padding: 0.75rem 2rem;
      color: $c-menu-item;
    }
  }

  &__content {
    flex: 1;
    overflow-y: scroll;
  }
}
