@import "react-datepicker/dist/react-datepicker.css";

.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  label {
    opacity: 0.7;
    margin-bottom: 0.2rem;
  }
}

input,
textarea {
  // background: $c-background-2-transparent;
  color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.input-disabled {
  opacity: 0.5;
}

.input-error {
  // border: 1px solid $c-red;
  color: $c-red;
}

.input-helper-text {
  font-size: 0.7rem;
  text-align: right;
}

.input-error-text {
  @extend .input-helper-text;
  color: $c-red;
}

.selector {
  position: relative;
  input {
    cursor: pointer;
  }

  &__list {
    position: absolute;
    max-height: 0;

    overflow: hidden;
    width: 100%;
    z-index: 1;
    border-radius: 0.5rem;
    // overflow: hidden;
    background: $c-background-2;

    &--open {
      @extend .selector__list;
      max-height: 300px;
      border: 1px solid $c-separator;
      overflow-y: scroll;
      margin-bottom: 2rem;
    }
  }

  &__item {
    padding: 0.75rem;
    cursor: pointer;
    &:hover {
      background: $c-background-3;
    }
  }
}

.selector--small {
  @extend .selector;
  input {
    font-size: 0.8rem;
    padding: 0.4rem;
  }
}

.list-dividor {
  border-top: 1px solid $c-separator;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
}

.multi-selector {
  // display: flex;
  margin-top: 0.25rem;

  &__item {
    background: $c-background-2-transparent;
    color: rgba(255, 255, 255, 0.5);
    padding: 0.3rem 0.5rem;
    // margin-right: 1rem;
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 0.9rem;
    &:hover {
      color: white;
    }

    &--selected {
      @extend .multi-selector__item;
      background: $c-blue;
      color: black;
      &:hover {
        color: black;
        opacity: 0.7;
      }
    }
  }

  &__simple-item {
    color: rgba(255, 255, 255, 0.4);
    padding: 0.3rem 0.5rem;
    // margin-right: 1rem;
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 0.9rem;
    // &:hover {
    //   color: white;
    // }

    &--selected {
      @extend .multi-selector__item;
      color: white;
      &:hover {
        color: black;
        opacity: 0.7;
      }
    }
  }
}

.form-row {
  display: flex;
  .input-wrapper {
    margin-right: 1rem;
  }
}

.form-section-header {
  border-bottom: 1px solid $c-separator;
}

.form-button-container {
  padding: 1rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.workout-form__set {
  position: relative;
  padding: 1rem;
  padding-top: 2rem;
  border: 1px solid $c-pink;
  margin-bottom: 2rem;
}

.workout-form__segments {
}

.workout-form__segment {
  position: relative;
  border: 1px solid $c-green;
  padding: 1rem;
  margin-bottom: 1rem;
}

.workout-form__lengths {
}

.workout-form__length {
  position: relative;
  border: 1px solid $c-blue;
  padding: 1rem;
  margin-bottom: 1rem;
  &--error {
    @extend .workout-form__length;
    border: 1px dashed $c-red;
  }
}

.form-section-delete-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  color: $c-red;
}

.hud-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: space-between;
  button {
    color: $c-green;
  }
}

.form-segment-stroke {
  font-size: 1.5rem;
  opacity: 0.7;
  text-transform: capitalize;
}

.form-submit-container {
  padding-bottom: 3rem;
  margin-top: 2rem;
  button {
    width: 100%;
    color: black;
    background: $c-green;
    padding: 1rem;
    font-weight: bold;
  }
}

.form-icon-list {
  display: flex;

  &__image {
    width: 75px;
    height: 75px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &--active {
      @extend .form-icon-list__image;
      border: 3px solid $c-pink;
    }
  }
}
