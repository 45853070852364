.modal-background {
  background: rgba(0,0,0,.5);
  position: fixed;
  top: 0; bottom: 0; right: 0; left: 0;
  z-index: 1;
}

.modal-inner-wrapper {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: $c-background-3;
  padding: 1.5rem;
  border-radius: .5rem;
  min-width: 500px;
  max-width: 600px;
}
