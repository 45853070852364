.activity-chat-wrapper {
  background: $c-background-2;
  padding: 1rem;
  padding-top: 1.5rem;
  overflow: hidden;
  border-radius: 1rem;
  position: fixed;
  top: 10.5rem;
  right: 2rem;
  width: 24rem;
  max-width: 100%;
  cursor: move;
  pointer-events: auto;
  touch-action: auto;
}

.activity-chat-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
  align-items: flex-start;
}

.activity-chat {
  display: flex;
  flex-direction: column;

  &__list {
    max-height: 20rem;
    overflow: hidden;
    overflow-y: scroll;
    padding: 1rem;
    border-radius: 1rem;
  }

  &__empty-list {
    padding: 1rem 0;
    text-align: center;
    color: $c-label-2;
  }

  &__footer {
    margin-top: 1rem;
    display: flex;
    background: blue;
    background: $c-background-3-transparent;
    border-radius: 1rem;
    padding-right: .5rem;
    input {
      background: transparent;
      flex: 1;
    }
  }

  &__send-button {
    padding: 0 .5rem;
    &:disabled {
      color: $c-label-2;
    }
  }
}

.activity-chat-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.2rem;
  &:first-child {
    margin-top: 0;
  }

  &--own {
    @extend .activity-chat-message;
    align-items: flex-end;
  }
}

.activity-chat-message-date {
  margin-bottom: .75rem;
  font-size: .7rem;
  font-weight: 600;
}

.activity-chat-message-content {
  display: flex;
  justify-content: flex-start;
  max-width: 75%;
  letter-spacing: -0.408px;
  line-height: 1.2;
  &--own {
    @extend .activity-chat-message-content;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }

  &__time {
    font-size: .75rem;
    font-weight: 600;
    line-height: .8rem;
    letter-spacing: 0.06px;
    color: $c-label-2;
    padding-top: .5rem;
    &--own {
      @extend .activity-chat-message-content__time;
      text-align: right;
    }
  }
}

.activity-chat-message-content__icon {
  margin-right: 1rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  &--own {
    @extend .activity-chat-message-content__icon;
    margin-right: 0;
    margin-left: 1rem;
  }
}
