.onboarding-container {
  max-width: 30rem;
  margin: 0 auto;
  margin-top: 4rem;
  padding: 1rem;
  // background: red;
}

.onboarding-back {
  height: 2rem;
  color: blue;
}

.onboarding-header {
  text-align: center;
  margin: 2rem 0;
  h1 {
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.36px;
  }
}

.onboarding-section {

}

.onboarding-next-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90.62deg, #FF506D 0.25%, #FF50D9 99.71%);
  color: white;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: .5rem;
  font-weight: bold;
  margin-top: 1rem;

  &:disabled {
    background: $c-background-3;
  }
}

.onboarding-button {
  background: $c-background-2;
  padding: 0 1.2rem;
  margin-bottom: .5rem;
  height: 4.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: .9;
  }

  &__text {
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.408px;
  }

  &__subtext {
    font-size: 15px;
    line-height: 20px;
    color: rgba(235, 235, 245, 0.6);
  }

  &--active {
    @extend .onboarding-button;
    background: white;
    .onboarding-button__text {
      color: $c-background-2;
    }

    .onboarding-button__subtext {
      color: #3C3C43;
    }

    &:hover {
      opacity: 1;
    }
  }
}
