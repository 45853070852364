.button {
  background: $c-pink;
  padding: .5rem 1rem;
  display: inline-block;
  width: inherit;
  color: black;
  border-radius: .5rem;
}

.button--secondary {
  @extend .button;
  background: grey;
  color: white;
}

.button--gradient {
  color: white;
  background: linear-gradient(90.62deg, #FF506D 0.25%, #FF50D9 99.71%);
}

.button--lg {
  font-size: .9rem;
  padding: .75rem 1rem;
  font-weight: 600;
  min-width: 7rem;
}
