.metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 2rem;
  &:last-child {
    margin: 0;
  }
}

.metric {
  min-width: 100px;
  &__value {
    font-size: 1.75rem;
  }

  &__title {
    font-size: 0.75rem;
    color: $c-label-2;
  }
}

.pdf-download-wrapper .metric,
.pdf-download-wrapper .metric__value,
.pdf-download-wrapper .metric__title {
  color: black !important;
}

.progress-bar {
  width: 100%;

  &__section {
    width: 100%;
    text-align: center;

    p {
      color: $c-label-2;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }
  }

  &__bar {
    height: 2rem;
    position: relative;

    &--strain {
      @extend .progress-bar__bar;
      background: #c78973;
    }

    &--grind {
      @extend .progress-bar__bar;
      background: #d6b986;
    }

    &--coast {
      @extend .progress-bar__bar;
      background: #adc48f;
    }

    &--taper {
      @extend .progress-bar__bar;
      background: #44a386;
    }

    &--waste {
      @extend .progress-bar__bar;
      background: #338a8f;
    }
  }

  &__marker {
    transform: translateY(-0.5rem);
  }

  &__line {
    height: 1rem;
    border-right: 1px solid white;
    transform: translateX(0.5px);

    &--left {
      @extend .progress-bar__line;
      border-left: 1px solid white;
    }
  }

  &__number {
    text-align: right;
    font-size: 0.75rem;
    padding-top: 0.25rem;
    color: $c-label-2;

    div {
      display: inline-block;
      transform: translateX(50%);
    }
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid $c-background-2;
  transform: translateX(-50%);
}

.ftp-bar {
  height: 0.5rem;
  width: 3.5rem;
  background: #c4c4c4;
  border-radius: 0.25rem;
  margin-right: 0.25rem;

  &--1 {
    @extend .ftp-bar;
    background: $c-red;
  }

  &--2 {
    @extend .ftp-bar;
    background: $c-yellow;
  }

  &--3 {
    @extend .ftp-bar;
    background: $c-green;
  }
}

.ftp-text {
  color: $c-label-2;
  &--1 {
    @extend .ftp-text;
    color: $c-red;
  }

  &--2 {
    @extend .ftp-text;
    color: $c-yellow;
  }

  &--3 {
    @extend .ftp-text;
    color: $c-green;
  }
}

.ftp-link {
  color: $c-blue;
  margin-top: 1rem;
}

.progress-best-times {
  display: flex;
}

.progress-best-times-table-header {
  display: flex;
  font-size: 1.25rem;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $c-separator;
  padding-left: 0.5rem;
}

.best-times-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
  th {
    text-align: left;
  }

  tr {
    &:hover {
      background: $c-background-3;
      cursor: pointer;
    }
  }

  th,
  td {
    padding: 0.5rem 0.5rem;
  }
}
